/* styles.css */
.container {
    max-width: 1300px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  .form-container {
    max-width: 500px; /* Adjust the maximum width as needed */
    width: 100%; /* Ensures the container expands to fill its parent */
  }

/* Style for the table */
.table-container {
  width: 100%;
  margin-top: 20px; /* Adjust margin as needed */
}

table {
  width: 100%;
  border-collapse: collapse;
}

th, td {
  padding: 8px;
  border: 1px solid #ddd;
  text-align: left;
}

th {
  background-color: #f2f2f2; /* Background color for table header */
}

tr:nth-child(even) {
  background-color: #f2f2f2; /* Alternate row color */
}

tr:hover {
  background-color: #ddd; /* Hover color for rows */
}

/* Style for buttons in table */
table button {
  padding: 6px 10px;
  margin-right: 5px;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  background-color: #007bff; /* Button background color */
  color: white;
}

table button:hover {
  background-color: #0056b3; /* Hover background color */
}

thead {
  background-color: #0099ff; /* Header background color */
  color: rgb(173, 117, 52); /* Header text color */
}

thead th {
  padding: 8px;
  border: 1px solid #ddd;
  text-align: left;
}

thead th:hover {
  background-color: #0056b3; /* Hover background color */
}

  input[type='email'],
  input[type='text'],
  button {
    margin-bottom: 10px;
    width: 100%;
    padding: 10px;
  }
  
  .message {
    color: #f00;
  }
  .wrap-content {
    max-width: 200px;
    word-wrap: break-word;
  }