.table-container {
    margin-bottom: 20px;
    border: 1px solid #ccc;
    padding: 10px;
  }
  
  .table-container.expanded {
    background-color: #f9f9f9;
  }
  
  .user-info {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .wrap-content {
    max-width: 200px;
    word-wrap: break-word;
  }